import React, {useEffect, useState} from 'react'
import logo from '../assets/image/logo.png';
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
const Header = () => {
    const menus = [
      {name: 'home', link: '/'},
      {name: 'service', link: '/service'},
      {name: 'about us', link: 'about-us'},
      {name: 'contact', link: '/contact'},

        // 'home', 'service', 'about us', 'blog', 'contact'
    ];
    const [openMobileNavbar, setOpenMobileNavbar] = useState(false);
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        setSticky(offset > 100);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <header className='flex flex-col'>
        {/* navbar start */}
        {/* <div className={`flex w-full bg-blue-800 justify-between items-center py-1 lg:px-10 px-4 shadow-md font-serif text-white ${isSticky && 'fixed top-0'} z-10 transition-all duration-300`} > */}
        {/* <div
        className={`flex w-full bg-blue-500 justify-between items-center py-1 lg:px-10 px-4 shadow-md font-serif text-white transition-all duration-100 ease-in-out ${
          isSticky ? 'sticky top-0 z-50' : ''
        }`} */}
        <div
        className={`flex w-full bg-blue-500 justify-between items-center py-1 lg:px-10 px-4 shadow-md font-serif text-white transition-all duration-100 ease-in-out sticky top-0 left-0
        }`}
      >
            <div className='flex justify-center items-center font-extrabold text-4xl'>
              <img src={logo} alt="" srcset="" className='w-28 '/>
              <h6>IDM</h6>
            </div>
            <div className=''>
                <ul className='hidden gap-20 text-xl cursor-pointer capitalize lg:flex text-slate-100 text p-6'>
                    {menus?.map((menu, key)=>{return(
                    <Link to={menu.link}>
                      <li key={key} className= "relative font-semibold w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center ">{menu.name} </li>
                      </Link>
                      )})}
                </ul>
            </div>
            {!openMobileNavbar ? 
            <IoIosMenu className='cursor-pointer flex lg:hidden text-5xl text-white' onClick={()=>setOpenMobileNavbar((prev)=>!prev) }/>
            :
            <IoMdClose className='cursor-pointer flex lg:hidden text-5xl text-white' onClick={()=>setOpenMobileNavbar((prev)=>!prev)}/>}
        </div>
        <ul className={`flex lg:hidden flex-col w-full backdrop-blur-sm justify-center items-center gap-10 capitalize cursor-pointer shadow-sm bg-blue-700 text-white transition-all ease-in-out duration-500 font-serif ${ !openMobileNavbar ? '-translate-y-96 opacity-0 max-h-0': 'translate-y-0 opacity-100 max-h-full p-10'}`}>
            {menus?.map((menu, key) => (
              <Link to={menu.link}>
            <li
            key={key}
            className="relative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"
            >
                {menu.name}
              </li>
              </Link>
            ))}
          </ul>
        {/* )} */}
        {/* navbar end */}


        
    </header>
  )
}

export default Header