import React from 'react'
import about_banner from '../assets/image/about-us-banner.jpg';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <section className='w-full h-full'>
      <Helmet>
            <meta charSet="utf-8" />
            <title>About Us | IDM Solutions - Innovating Digital Transformation</title>
            <link rel="canonical" href="https://idmsolution.in/about-us"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description" content="Discover the Story Behind IDM Solutions. We Specialize in Driving Digital Transformation with Cutting-Edge Solutions. Learn About Our Mission, Values, and Commitment to Excellence" />
            <meta property="og:title" content="About Us | IDM Solutions - Innovating Digital Transformation" />
            <meta property="og:description" content="Discover the Story Behind IDM Solutions. We Specialize in Driving Digital Transformation with Cutting-Edge Solutions. Learn About Our Mission, Values, and Commitment to Excellence" />
            <meta property="og:url" content="https://idmsolution.in/about-us" />
        </Helmet>
        <div className='flex flex-col lg:flex-row justify-between items-center sm:px-2 lg:px-20'>
            <div className='w-full lg:w-2/4 text-center p-4 flex flex-col gap-5 '>
              <div className='flex flex-col items-center group'>
                  <h1 className='2xl:text-4xl xl:text-2xl text-2xl font-bold'>Our Services</h1>
                  <span className='bg-black w-2/12 h-1  top-full origin-left transition-transform duration-300 transform scale-x-0 animate-slide-in'></span>
              </div>
                <p className='text-left 2xl:text-2xl text-xl font-normal sm:text-center lg:text-left'>IDM Solution leads the industry in desktop and mobile cross-platform development, engineering, and consulting. We cater to a diverse client base, from tech startups to large corporations. Our global delivery centers offer flexible onsite, offsite, and offshore services, specializing in custom software solutions. With deep client relationships spanning startups to established firms, our commitment to innovation is unwavering.</p>
            </div>
            <div className='w-full lg:w-2/4'>
                <img src={about_banner} alt="" srcset="" className='w-full'/>
            </div>
        </div>
        <div className='w-full flex justify-center items-center'>
          {/* <div className='md:w-2/4 w-3/4 h-full flex flex-col gap-5 items-center justify-center'> */}
          <div className='w-full h-full flex flex-col gap-5 items-center justify-center p-5'>
            <div className='flex flex-col items-center group'>
                  <h1 className='text-2xl font-semibold '>Why Choose IDM Solution?</h1>
                  <span className='bg-black w-52 h-1  top-full origin-left transition-transform duration-300 transform scale-x-0 animate-slide-in'></span>
              </div>
              <div className='text-xl font-normal leading-relaxed sm:px-2 lg:px-32 sm:text-center lg:text-left'>
                <p>Choose IDM Solution for an Expert Team Dedicated to Your Success, Tailored Solutions for Unique Business Needs, a Client-Centric Approach Emphasizing Communication and Collaboration, Continuous Innovation to Stay Ahead in Tech, and a Partnership for Digital Transformation. Let's Build Together! Whether You're a Startup or an Established Business, Idm Solution Is Your Partner for Success. Contact Us to Elevate Your Digital Presence.
</p>
              </div>
          </div>
        </div>
    </section>
  )
}

export default AboutUs