import React, { useState } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import serviceBanner from '../assets/image/service-banner.png';
import Logo1 from '../assets/image/clients/logo1.png';
import { TbSettingsSearch } from "react-icons/tb";
import { FaLaptopCode } from "react-icons/fa";
import { MdAppSettingsAlt } from "react-icons/md";
import { MdSocialDistance } from "react-icons/md";
import { CiMonitor } from "react-icons/ci";
import { GrEmoji } from "react-icons/gr";
import { GiPalette } from "react-icons/gi";
import { PiChartLineUpFill } from "react-icons/pi";

import { FaMapMarkerAlt } from "react-icons/fa";
import { RiFolderSettingsLine } from "react-icons/ri";
import { AiOutlineAudit } from "react-icons/ai";
const Service = () => {

  const [firstVisibleCard, setFirstVisibleCard] = useState(0);
  const device_width = window.innerWidth;
  let slide_count = 3;
  if(device_width < 1024){
    slide_count = 1;
  }
  const handleNext = () => {
    setFirstVisibleCard((prevFirstVisibleCard) =>
      prevFirstVisibleCard + 1 <= cardsData.length-slide_count ? prevFirstVisibleCard + 1 : 0
    );
  };

  const handlePrev = () => {
    setFirstVisibleCard((prevFirstVisibleCard) =>
      prevFirstVisibleCard - 1 >= 0
        ? prevFirstVisibleCard - 1
        : cardsData.length - slide_count
    );
  };
  
    
    const cardsData = [
      { icon: <GiPalette />, title: 'Graphics  Design ', description: "Discover Excellence with IDM Solution – India's Top Graphic Design Firm! Our Exceptional Design Services Come at Highly Competitive Rates, Helping Elevate Your Visual Identity. Let's Create Outstanding Graphic Design Solutions Together, Tailored Just for You!" },
      { icon: <CiMonitor />, title: 'Web Design', description: "Empower Your Brand's Success by Investing in a Leading Indian Web Design Company. Harness the Internet's Potential with a Website That Captivates, Converts, and Inspires. Maximize Your Online Impact with Our Stunning Web Design Solutions." },
      { icon: <GrEmoji />, title: 'Meme Marketing', description: "Explore the World of Meme Marketing with IDM, India's Top Meme Marketing Company! Let Us Enhance Your Business Branding Using Engaging Memes. Our Expert Team Is Ready to Seamlessly Boost Your Brand Awareness. Elevate Your Marketing Game with IDM!" },
      { icon: <PiChartLineUpFill />, title: 'SEM', description: "Boost Online Visibility with Our Premium Search Engine Marketing (SEM) Service. Elevate Your Brand, Attract Targeted Traffic, and Optimize Digital Presence with Expert Strategies. Unleash the Full Potential of Effective Online Advertising with the Finest SEM Service Available." },
      { icon: <MdSocialDistance />, title: 'Social Media Marketing', description: "Elevate Your Brand's Online Presence with the Leading Social Media Marketing Company in India. Our Skilled Team Crafts Effective Strategies to Enhance Your Brand Across Various Platforms, Supercharging Your Online Visibility." },
      { icon: <TbSettingsSearch />, title: 'SEO Services', description: "Supercharge Your Brand Online! Opt for Our Agency's Top SEO and Digital Marketing Services. Boost Visibility, Elevate Your Online Presence, and Achieve Digital Success with Expert Strategies. Unparalleled Results Await - join Us on the Path to Online Excellence!" },
      { icon: <FaLaptopCode />, title: 'Web Development', description: "Transform Your Online Presence with the Finest Web Development Services in India! Our Expert Team Crafts Customized Websites, Ensuring Your Brand Stands Out. Elevate Your Digital Identity with Top-Notch Development That Speaks Uniquely to Your Brand." },
      { icon: <MdAppSettingsAlt />, title: 'App Development', description: "Unlock Your Ideas with India's Top Mobile App Development Company! We Excel in Crafting Seamless Android and ios Apps, Elevating Your Brand's Online Presence. Trust Our Expert Development Services for a Flawless User Experience That Sets Your Business Apart." },
    ];    
    const servicesData = [
      { icon: <AiOutlineAudit />, title: 'Free Website Audit', description: "Let's Fix Your Online Customer Problem..! We Offer Free Consultations and Solutions to Boost Your Online Presence" },
      { icon: <RiFolderSettingsLine />, title: 'Website Customization', description: "Tailor your website with our custom solutions seamlessly crafted for you. We work on your website for free, ensuring a personalized and effective online presence" },
      { icon: <GrEmoji />, title: 'Meme Marketing', description: "Nowadays, Meme Marketing Is a Trend in Social Media. We Freely Provide Meme Marketing Services for Your Business" },
      { icon: <FaMapMarkerAlt />, title: 'Local SEO', description: "You Have Not Optimized Your Google My Business Profile. We Offer Free Optimization to Increase Visibility and Attract More Local Customers to Your Business" }
    ]
    const clientsLogo = [Logo1, Logo1, Logo1, Logo1, Logo1, Logo1];
  return (
    <div className='p-10 border bg-white flex flex-col gap-5 text-black'>
      <div className='flex flex-col gap-5'>
        <h1 className='text-center text-4xl font-bold'>Services we offer</h1>
        {/* <p className='px-1 xl:px-10 items-center justify-center mx-10 xl:mx-40 text-sm sm:text-xl xl:text-2xl '>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quo blanditiis ad assumenda modi magni ullam aliquam ducimus impedit adipisci! Eveniet, obcaecati maiores? Ratione nulla tempora quod, deleniti enim explicabo! Ratione nulla tempora quod, deleniti enim explicabo!Ratione nulla tempora qu</p> */}
      </div>
      <div className='flex w-full mt-10'>
        <div className='w-2/12 flex items-center justify-end ' onClick={handlePrev}>
          <div className='text-3xl bg-white text-black shadow-lg p-5 rounded-full'>
            <FaArrowLeft />
          </div>
        </div>
        
        
        <div className=" w-1-/12 overflow-hidden relative">
          <div className={` flex transition-transform duration-300 ease-in-out `} style={{ transform: `translateX(-${firstVisibleCard * (100 / slide_count)}%)` }}
          >

            {cardsData.map((card, index) => (
              <div key={index} className=" px-4 flex-none w-fit sm:w-full lg:w-1/3 hover:scale-105 transition-scale ease-in-out duration-300 cursor-context-menu" >
                <div className="bg-blue-500 p-4 rounded shadow text-slate-100 sm:flex lg:flex-none flex-col gap-4">
                  <div className='flex items-end justify-center gap-5'>
                    <div className="sm:text-4xl lg:text-base mb-2">{card.icon}</div>
                    <h2 className="sm:text-4xl lg:text-base font-bold mb-2">{card.title}</h2>
                  </div>
                  
                  <p className='break-words sm:text-4xl lg:text-base text-center'>{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='w-2/12 flex items-center justify-start' onClick={handleNext}>
          <div className='text-3xl bg-white text-black shadow-lg p-5 rounded-full'>
            <FaArrowRight />
          </div>
        </div>
      </div>
      {/* <div className='p-10 border border-red-100 mx-20 flex rounded-lg text-slate-900 backdrop-blur-3xl bg-white'>
        <p className='px-1 xl:px-5 items-center justify-center mx-5 xl:mx-10 text-sm sm:text-xl xl:text-2xl my-5 font-serif text-left sm:text-center'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias quo blanditiis ad assumenda modi magni ullam aliquam ducimus impedit adipisci! Eveniet, obcaecati maiores? Ratione nulla tempora quod, deleniti enim explicabo! Ratione nulla tempora quod, deleniti enim explicabo!Ratione nulla tempora qu</p>
      </div> */}
      <div className='flex justify-around items-center p-0 sm:p-10 gap-10 flex-col xl:flex-row max-w-fit'>
        <div className='w-full xl:w-3/4 2xl:w-1/4  h-max-[800px]'>
          {/* <img src={serviceBanner} alt="" srcset="" className='bg-cover w-full rounded-2xl'/> */}
          <img src={serviceBanner} alt="" srcset="" className='sm:w-full sm:h-full w-full rounded-2xl object-cover' />
        </div>
        <div className='flex flex-col gap-4 w-full xl:w-2/4 justify-center '>
        {servicesData.map((serviceData,key)=>{return (
        <div className='flex gap-10 justify-center text-4xl' key={key}>
          <div className='flex items-center justify-center bg-blue-500 font-bold text-white drop-shadow-2xl shadow-2xl p-2 w-12 h-12 rounded-full'>{serviceData.icon}</div>
          <div>
            <h1 className='font-bold text-2xl text-blue-600'>{serviceData.title}</h1>
            <p className='text-xl text-slate-600 '>{serviceData.description}</p>
          </div>
        </div>
        )})}
        </div>
      </div>
    </div>
  )
}

export default Service