import React from 'react'
import { TbSettingsSearch } from "react-icons/tb";
import { FaLaptopCode } from "react-icons/fa";
import { MdAppSettingsAlt } from "react-icons/md";
import { MdSocialDistance } from "react-icons/md";
import { CiMonitor } from "react-icons/ci";
import { GrEmoji } from "react-icons/gr";
import { GiPalette } from "react-icons/gi";
import { PiChartLineUpFill } from "react-icons/pi";

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Services = () => {
    const servicesData = [
        {
            icon: <TbSettingsSearch />,
            title: 'SEO Service',
            description: "Get the Best Seo and Digital Marketing Services from Our Agency! Boost Your Online Presence, Increase Visibility, and Achieve Success in the Digital World with Our Expert Strategies. Choose Us for Unparalleled Results!",
        },
        {
            icon: <FaLaptopCode />,
            title: 'Web Development',
            description: 'Choose the Best Web Development Company in India for Custom Websites. Our Experts Create Tailored Solutions for a Standout Online Presence. Elevate Your Brand with Top-Notch Development Services!',
        },
        {
            icon: <MdAppSettingsAlt />,
            title: 'App Development',
            description: "Transform Your Ideas into Reality with India's Top Mobile App Development Company. We Excel in Crafting Android and iOS Apps, Delivering Seamless Solutions. Elevate Your Digital Presence with Our Expert Development Services!",
        },
        {
            icon: <MdSocialDistance />,
            title: 'Social Media Marketing',
            description: "Supercharge Your Brand's Online Presence with the Premier Social Media Marketing Company in India. Our Expert Team Designs Effective Strategies to Elevate Your Brand on Various Platforms.",
        },
        {
            icon: <CiMonitor />,
            title: 'Website Design',
            description: "Investing in a Top Indian Web Design Company is an Investment in Your Brand's Success. Unleash the Power of the Internet with a Stunning Website That Converts and Inspires",
        },
        {
            icon: <PiChartLineUpFill />,
            title: 'SEM Service',
            description: "Maximize Online Visibility with Our Top-Notch Search Engine Marketing Service. Elevate Your Brand and Drive Targeted Traffic with Our Expert Strategies for Effective Online Advertising. Unlock the Full Potential of Your Digital Presence with the Best Sem Service Available.",
        },
        {
            icon: <GrEmoji />,
            title: 'Meme Marketing',
            description: "Explore the Trend of Meme Marketing with IDM, the Best Meme Marketing Company in India! Let Us Help You Brand Your Business Using Engaging Memes. Our Expert Team Is Here to Boost Your Brand Awareness Seamlessly.",
        },
        {
            icon: <GiPalette />,
            title: 'Graphics  Design',
            description: "IDM Solution, recognized as India's premier graphic design firm, offers exceptional design services at highly competitive rates. Elevate your visual identity with our top-notch graphic design solutions.",
        },
    ];
  return (
    <div className='w-full my-10'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Services | Web Development Services | Meme Marketing Company</title>
            <link rel="canonical" href="https://idmsolution.in/service"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description" content="Elevate Your Business with IDM Solutions. Expertise in Web Development, Design, and Marketing for Unparalleled Digital Success. Discover Innovation with Us!" />
            <meta property="og:title" content="Services | Web Development Services | Meme Marketing Company" />
            <meta property="og:description" content="Elevate Your Business with IDM Solutions. Expertise in Web Development, Design, and Marketing for Unparalleled Digital Success. Discover Innovation with Us!" />
            <meta property="og:url" content="https://idmsolution.in/service" />
        </Helmet>
        <div className='w-full px-20 flex flex-col justify-center items-center gap-10 '>
            <div className='flex flex-col items-center group'>
                <h1 className='text-2xl font-bold'>Our Services</h1>
                <span className='bg-black w-28 h-1  top-full origin-left transition-transform duration-300 transform scale-x-0 animate-slide-in'></span>
            </div>
            <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5'>
            {servicesData.map((service, index) => (
                <Link to="/contact">
                <div className='flex flex-col items-center justify-center w-full px-3 hover:bg-blue-600 rounded-3xl hover:text-white transition-colors duration-300 border shadow-lg group cursor-pointer' key={index}>
                    <div className='w-full flex flex-col items-center justify-center p-4 gap-2 my-6'>
                        <h1 className='2xl:text-6xl text-4xl font-semibold text-blue-500 group-hover:text-white'>
                            {service.icon}
                        </h1>
                        <h3 className='2x:text-2xl lg:text-xl font-semibold text-center'>{service.title}</h3>
                        <div className='text-center w-full 2xl:text-lg text-sm font-light'>{service.description}</div>
                    </div>
                </div>
                </Link>
            ))}
            </div>
        </div>
    </div>
  )
}

export default Services