import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './screen/Home';
import ContactUs from './screen/ContactUs';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Services from './screen/Services';
import AboutUs from './screen/AboutUs';
export default function App() {
  return (
      <BrowserRouter>
      <Header />
      <Routes>
          <Route index element={<Home />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="service" element={<Services />} />
          <Route path="about-us" element={<AboutUs />} />
          {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
      <Footer className="lg:mb-0" />
    </BrowserRouter>
 
  )
}