import React from 'react'
import Header from '../layout/Header'
import Service from '../layout/Service'
import ServicePage from'../layout/ServicePage'
import Testimonial from '../layout/Testimonial'
import Footer from '../layout/Footer'
import banner from '../assets/image/banner.png';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom'
const Home = () => {
  return (
        <div className='h-full bg-white w-full'>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Best App Development Company | Web Development Services | IDM</title>
                <link rel="canonical" href="https://idmsolution.in/"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="IDM Solution Is the Best App Development Company. We Are Offering Solutions to Build Your Business Online. We Provide Digital Marketing and Web Development Services." />
                <meta property="og:title" content="Best App Development Company | Web Development Services | IDM" />
                <meta property="og:description" content="IDM Solution Is the Best App Development Company. We Are Offering Solutions to Build Your Business Online. We Provide Digital Marketing and Web Development Services." />
                <meta property="og:url" content="https://idmsolution.in/" />
            </Helmet>


          {/* Header start */}
        <div className='flex flex-col lg:flex-row px-2 sm:items-center sm:justify-between justify-center items-center'>
            <div className='sm:p-20 p-10 text-center lg:text-left flex flex-col sm:gap-6 lg:gap-2'>
              <h2 className=' sm:text-6xl text-lg leading-normal'>Great <span className='text-blue-500'>Product</span> is <p className='font-bold capitalize'>build by great <span className='text-blue-600'>Team</span></p> </h2>
              <p className='py-2 sm:text-4xl text-base sm:leading-10 leading-6 lg:text-2xl flex-wrap'>IDM Solution is One of the Best Digital Marketing Company in India. We Offer Solutions to Help You Build Your Business Online. We Provide Affordable Prices for High-Quality Services. </p>
              <Link to="/contact"><button type="button" className='bg-blue-500 sm:px-20 px-5 sm:py-4 py-2 text-white sm:text-3xl text-base font-semibold rounded-full hover:bg-blue-700 hover:text-gray-50 transition-all duration-500 ease-in-out hover:scale-105'>Let's get started </button></Link>
            </div>
            <div className='sm:p-20 sm:py-0 w-full h-full'>
              <img src={banner} alt="" srcset="" className='sm:w-full sm:h-full w-80' />
            </div>
        </div>
        {/* Header end */}
            <Service />
            <Testimonial />
            {/* <ServicePage /> */}
        </div>
  )
}

export default Home

