// Testimonial.js

import React, { useState, useEffect } from 'react';
import Avatar from '../assets/image/Testimonial/avatar1.png';
import AlJannath from '../assets/image/Testimonial/AlJannath.jpg';
import BeesKitchen from '../assets/image/Testimonial/BeesKitchen.jpg';
import RazikDress from '../assets/image/Testimonial/razikdress.jpg';
import StkOil from '../assets/image/Testimonial/stkoil.jpg';

const testimonials = [
  {
    id: 1,
    avatar: StkOil,
    header: 'stk oil',
    job: '',
    review:
      'Before IDM Solution stepped in, my Google My Business account was not bringing in local clients for STK Oil. Thanks to their expertise in optimizing GMB and managing social media, I now receive more calls and leads. Their help has made a noticeable impact!',
  },
  {
    id: 2,
    avatar: RazikDress,
    header: 'Rasik extrame',
    review:
      "Thanks to IDM Solution for social media marketing and YouTube ads, we've received inquiries. They also optimized our website for SEO, resulting in organic leads",
  },
  {
    id: 3,
    avatar: AlJannath,
    header: 'Al Jannath Haj Service',
    review:
      "As a travel agency, we've encountered difficulties attracting online customers. To overcome this, we devised a plan to advertise on Facebook and YouTube. Upon consulting with IDM Solution, their support led to a successful increase in our online customer base",
  },
  {
    id: 4,
    avatar: BeesKitchen,
    header: 'Bees Kitchen',
    review:
      "We run a food shop in Chennai and wanted to advertise online. Upon my friend's recommendation, we approached IDM Solution. After sharing our requirements, they promoted our store through social media, helping us reach numerous local customers",
  },

  
];

const Testimonial = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentTestimonial]);
  const [firstVisibleCard, setFirstVisibleCard] = useState(0);
  const device_width = window.innerWidth;
  let slide_count = 1;
  if(device_width < 1024){
    slide_count = 1;
  }
  const handleNext = () => {
    setFirstVisibleCard((prevFirstVisibleCard) =>
      prevFirstVisibleCard + 1 <= testimonials.length-slide_count ? prevFirstVisibleCard + 1 : 0
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFirstVisibleCard((prevFirstVisibleCard) =>
      prevFirstVisibleCard + 1
    );
    testimonials.push(testimonials[firstVisibleCard])
    }, 5000);
    return () => clearInterval(intervalId);
  }, [firstVisibleCard]);
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Clients Love Us!</h2>
          <p className="text-gray-500">Read what our clients have to say about us.</p>
        </div>
        <div className="relative overflow-hidden">
          {/* <div className="testimonial-slider flex w-full " > */}
          <div className={` flex transition-transform duration-300 ease-in-out w-full`} style={{ transform: `translateX(-${firstVisibleCard * (100 / slide_count)}%)` }}>

            {testimonials.map((testimonial, index) => (
              // <div
              //   key={testimonial.id}
              //   className={`testimonial-slide w-full flex-shrink-0 transform transition-transform duration-300 ${
              //     index === currentTestimonial ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
              //   }`}
              // >
              //   <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg flex flex-col items-center">
              //     <img
              //       src={testimonial.avatar}
              //       alt={`Avatar for ${testimonial.header}`}
              //       className="w-20 h-20 mx-auto mb-4 rounded-full"
              //     />
              //     <h3 className="text-2xl font-bold mb-2 uppercase">{testimonial.header}</h3>
              //     <h5 className="text-xl font-semibold mb-2 uppercase">{testimonial.job}</h5>
              //     <p className="text-gray-50 font-light text-lg font-serif leading-loose text-center">
              //       {testimonial.review}
              //     </p>
              //   </div>
              // </div>
              <div
                key={testimonial.index}
                className={`testimonial-slide w-full flex-shrink-0 transform transition-transform duration-300 shadow-2xl }`}
              >
              <div key={index} className="cursor-context-menu bg-white text-white p-6 rounded-lg flex flex-col items-center w-full  " >
                <div className=" p-4 rounded text-slate-700 flex flex-col items-center">
                  <div className='flex flex-col items-center justify-center gap-1'>
                    {/* <div className="text-2xl mb-2">{testimonial.avatar}</div> */}
                    <img
                    src={testimonial.avatar}
                    alt={`Avatar for ${testimonial.header}`}
                    className="w-20 h-20 mx-auto rounded-full border border-black"
                  />
                    <h2 className="text-2xl font-bold uppercase">{testimonial.header}</h2>
                    <h2 className="text-xl font-semibold mb-4 uppercase">{testimonial.job}</h2>
                  </div>
                  
                  <p className='break-words capitalize text-center'>{testimonial.review}</p>
                </div>
              </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
