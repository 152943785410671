// Footer.js

import React from 'react';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS

import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";



const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4 h-full z-20">
      <div className="max-w-6xl mx-auto flex justify-between items-center p-4">
        <div>
          <h4 className="text-xl font-semibold mb-2 text-gray-300">Contact Us</h4>
          <p>39/33A, North East Kovikal Street</p>
          <p>Tenkasi - 627811</p>
          <p>Email: contact@idmsolution.com</p>
          <p>Phone: +91 - 8682872566</p>
        </div>
        <div className='flex flex-col gap-6'>
          <h4 className="text-xl font-semibold mb-2">Follow Us</h4>
          <div className="flex space-x-4 text-2xl">
            <a href="#" className="text-gray-300 hover:text-white">
              <FaFacebook />
            </a>
            <a href="#" className="text-gray-300 hover:text-white">
            <FaLinkedin />
            </a>
            <a href="#" className="text-gray-300 hover:text-white">
            <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
