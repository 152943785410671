import React from 'react'
import { Helmet } from 'react-helmet';
// import { FaTwitter } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
const ContactUs = () => {
  return (
    <div className='flex flex-col gap-10 p-5 bg-slate-100'>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Contact IDM Solutions for Digital Excellence | Reach Out Today</title>
            <link rel="canonical" href="https://idmsolution.in/contact"/>
            <meta name="robots" content="index, follow"/>
            <meta name="description" content="Connect with IDM Solutions for Innovative Digital Solutions. Reach Out to Us for Inquiries, Support, or Collaborations. Your Gateway to Digital Excellence." />
            <meta property="og:title" content="Contact IDM Solutions for Digital Excellence | Reach Out Today" />
            <meta property="og:description" content="Connect with IDM Solutions for Innovative Digital Solutions. Reach Out to Us for Inquiries, Support, or Collaborations. Your Gateway to Digital Excellence." />
            <meta property="og:url" content="https://idmsolution.in/contact" />
        </Helmet>
      <div className='flex flex-col gap-3'>
        <h1 className='font-bold text-center text-5xl'>Contact Us</h1>
        <p className='text-center font-normal text-slate-500 text-xl'>Our Focus is on Delivering Solutions, Not Just Services.</p>
      </div>
      <div className='flex lg:flex-row flex-col mx-10 justify-between'>
        <div className='bg-blue-500 p-16 text-white flex flex-col gap-10 lg:w-2/5 w-full'>
          <div className='flex flex-col'>
            <h1 className='text-3xl font-semibold'>Contact Information</h1>
            <p className='text-lg font-light'>Say something to start a live chat!</p>
          </div>
          <div className='flex flex-col gap-2 font-medium'>
          <p>Address - 39/33A, North East Kovikal Street, Tenkasi - 627811</p>
          <p></p>
          <p>Email: contact@idmsolution.com</p>
          <p>Phone: +91 - 8682872566</p>
            {/* <p>+1012 3456 789</p>
            <p>demo@gmail.com</p>
            <p>132 Dartmouth Street Boston, Massachusetts 02156 United States</p> */}
          </div>
          <div className='flex gap-4'>
            <h1 className='hover:bg-white text-black cursor-pointer p-2 rounded-full'><FaFacebook /></h1>
            <h1 className='hover:bg-white text-black cursor-pointer p-2 rounded-full'><FaInstagram /></h1>
            <h1 className='hover:bg-white text-black cursor-pointer p-2 rounded-full'><FaLinkedin /></h1>
          </div>
        </div>
        <div className='lg:w-3/5 w-full'>
          <div className='w-full p-16 bg-white flex flex-col gap-5'>
            <div className=' w-full flex flex-wrap'>
              <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name" className='font-medium'>First Name</label>
                <input type="text" name="" id="" className='bg-transparent border border-slate-400 outline-none px-2 rounded-md py-1' placeholder='first name'/>
              </div>
              <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name">Last Name</label>
                <input type="text" name="" id="" className='bg-transparent border border-slate-400 outline-none px-2 rounded-md py-1' placeholder='last name'/>
                {/* <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/> */}
              </div>
              <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name">Email</label>
                <input type="text" name="" id="" className='bg-transparent border border-slate-400 outline-none px-2 rounded-md py-1' placeholder='xyz@gmail.com'/>
                {/* <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/> */}
              </div>
              <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name">Phone Number</label>
                <input type="text" name="" id="" className='bg-transparent border border-slate-400 outline-none px-2 rounded-md py-1' placeholder='+919876543210'/>
                {/* <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/> */}
              </div>
              {/* <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name"> first name</label>
                <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/>
              </div> */}
              {/* <div className='lg:w-2/4 w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name"> first name</label>
                <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/>
              </div> */}
              <div className=' w-full flex flex-col gap-2 lg:px-8 px-2'>
                <label htmlFor="first name"> Message</label>
                <textarea name="" id="" cols="30" rows="10" className='bg-transparent border border-slate-400 outline-none p-2'></textarea>
                {/* <input type="text" name="" id="" className='bg-transparent border-b border-black outline-none'/> */}
              </div>
            </div>
            <div className='flex lg:justify-end justify-center py-16 px-8'>
              <input type="button" value="Send message" className='px-8 py-3 rounded-lg bg-blue-600 text-white'/>
            </div>
          </div>
        </div>
      </div>
      <div>
      <div className="w-full">
  <div className="map-container">
    <iframe
      title="Google Map Embed"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.186845847201!2d77.31530987477966!3d8.954924091103473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0429febe635587%3A0x6c16f6d5625af0c9!2sIDM%20Solution!5e0!3m2!1sen!2sin!4v1707065418654!5m2!1sen!2sin"
      width="100%" // Use 100% width
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>


      </div>
    </div>
  )
}

export default ContactUs